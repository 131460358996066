
import $app from '@/plugins/modules';
import { System } from '@/modules/system'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { CustomerLinks, IPatient, IVisit, IVisitPayment } from '@/modules/customers/types';
import { IPlace } from '@/modules/clinic/types';
import { IDateRange } from '@/modules/system/types';
import { IGlossaryItem } from '@/common/types';

@Component
export default class VisitDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => null })
  readonly id: number | undefined;

  @Prop({ default: () => null })
  readonly patientId: number | undefined;

  @Prop({ default: () => null })
  readonly doctor: number | undefined;

  @Prop({ default: () => null })
  readonly place: number | undefined;

  @Prop({ default: () => null })
  readonly from: Date | undefined;

  @Prop({ default: () => null })
  readonly to: Date | undefined;

  system = $app.module(System);

  item: IVisit | null = null;
  payment: IVisitPayment = { payType: null, amount: 0 };
  places: Array<IPlace> = [];
  dayVisits: Array<IVisit> = [];

  findPatient = false;
  processing = false;
  loading = false;
  showPay = false;
  showCancel = false;
  isPrepaid = false;
  invalidRange = false;

  @Watch('value')
  onShow() {
    this.reload();
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  get company() {
    return this.system.$store.getCurrentUser.companyId;
  }

  get time(): IDateRange {
    if (this.item) {
      return {
        from: this.item && this.item.timeFrom ? new Date(this.item.timeFrom) : null,
        to: this.item && this.item.timeTo ? new Date(this.item.timeTo) : null
      };
    } else {
      return { from: null, to: null }
    }
  }

  set time(value: IDateRange) {
    if (this.item) {
      this.item.timeFrom = value && value.from ? value.from.native(true) : null;
      this.item.timeTo = value && value.to ? value.to.native(true) : null;
    }
  }

  get debt(): number {
    return !this.item ? 0 : (((this.item.payedAmount || 0) + this.item.prepaid) - (this.item.totalAmount || 0));
  }

  get hasDebt(): boolean {
    return this.debt < 0;
  }

  get stateClass(): string {
    let cls = '';
    if (this.item) {
      if (this.item.state === 'P') {
        cls += 'primary--text text--lighten-2';
      } else if (this.item.state === 'A') {
        cls += 'success--text text--lighten-2';
      } else if (this.item.state === 'C') {
        cls += 'secondary--text text--lighten-3';
      } else if (this.item.state === 'D' && this.debt < 0) {
        cls += 'error--text text--lighten-2';
      } else if (this.item.state === 'D' && this.debt >= 0) {
        cls += 'success--text text--lighten-2';
      } else if (this.item.state === 'Y' && this.debt < 0) {
        cls += 'error--text text--lighten-2';
      } else if (this.item.state === 'Y' && this.debt >= 0) {
        cls += 'success--text text--lighten-2';
      }
    }
    return cls;
  }

  get invalid(): boolean {
    return !this.item ||
      !this.item.doctor ||
      !this.item.place;
  }

  get canStore(): boolean {
    return !this.invalid &&
      !this.invalidRange &&
      this.item!.patientId !== null &&
      this.item!.timeFrom !== null &&
      this.item!.timeTo !== null;
  }

  get readonly(): boolean {
    return this.item === null ||
      this.item === undefined ||
      this.item.state === 'D' ||
      this.item.state === 'Y' ||
      this.item.state === 'C';
  }

  get busy(): Array<IDateRange> {
    return this.dayVisits
      .filter(v => this.item && v.place === this.item.place && v.id !== this.item.id && v.state !== 'C')
      .map(v => {
        return { from: v.timeFrom ? new Date(v.timeFrom) : null, to: v.timeTo ? new Date(v.timeTo) : null }
      });
  }

  get placesGlossary(): Array<IGlossaryItem<number>> {
    return this.places.map(p => {
      return {
        code: p.id || 0,
        color: 'primary lighten-3',
        icon: 'fa-chair',
        label: p.name
      }
    });
  }

  get selectedPlace() {
    return this.places.find(p => this.item && p.id === this.item.place);
  }

  get motiveGlossary(): Array<IGlossaryItem<string>> {
    return [
      {
        code: '1',
        color: 'primary',
        icon: '',
        label: 'dplus.visits.Edit.VisitMotiveCode.1'
      },
      {
        code: '2',
        color: 'primary',
        icon: '',
        label: 'dplus.visits.Edit.VisitMotiveCode.2'
      },
      {
        code: '3',
        color: 'primary',
        icon: '',
        label: 'dplus.visits.Edit.VisitMotiveCode.3'
      }
    ];
  }

  goToPatient() {
    if (this.item) {
      if (this.item.patientId && this.item.id) {
        if (this.$route.name !== 'CustomerEdit') {
          const route = this.$router.resolve({ name: CustomerLinks.CustomerEdit, params: { EntityId: '' + this.item.patientId } })
          window.open(route.href, '_blank');
        }
      } else {
        this.findPatient = true;
      }
    }
  }

  selectPatient(patient: IPatient) {
    if (this.item) {
      this.item.patientId = patient.patientId;
      this.item.patientName = patient.patientLastName + ' ' + patient.patientFirstName;
      this.item.patientPhone = patient.patientPhone;
      this.item.photo = patient.photo;
    }
    this.findPatient = false;
  }

  checkRange(value: boolean) {
    this.invalidRange = value;
  }

  async reload() {
    if (this.value) {
      try {
        this.loading = true;
        this.places = await $app.get('/api/clinics/places');

        if (this.patientId && this.id) {
          this.item = await $app.get('/api/patients/' + this.patientId + '/visits/' + this.id);
        } else {
          let patient: IPatient | null = null;
          if (this.patientId) {
            patient = await $app.get('/api/patients/' + this.patientId);
          }

          this.item = {
            id: null,
            clinicId: null,
            state: 'P',
            cancelCode: null,
            place: this.place || null,
            patientId: patient ? patient.patientId : null,
            patientName: patient ? patient.patientLastName + ' ' + patient.patientFirstName : null,
            patientPhone: patient ? patient.patientPhone : null,
            patientBirthday: patient ? patient.patientBirthday : null,
            photo: patient ? patient.photo : null,
            doctor: this.doctor || null,
            visitDate: this.from ? this.from.native(false) : new Date().native(false),
            timeFrom: this.from ? this.from.native(true) : null,
            timeTo: this.to ? this.to.native(true) : (this.from ? this.from.addMin(30).native(true) : null),
            visitPayed: null,
            totalAmount: 0,
            payedAmount: 0,
            prepaid: 0,
            originalAmount: 0,
            totalDebt: 0,
            discount: 0,
            notes: '',
            motive: '3',
            payType: null,
            smsSent: null,
            manipulations: [],
            processing: false
          }
        }
        await this.reloadVisits();

        this.payment = { payType: this.item!.payType, amount: 0 };
      } catch (err) {
        $app.pushError(err);
      }
    }
    this.loading = false;
  }

  async reloadVisits() {
    this.dayVisits = await $app.get('/api/visits/range', {
      from: new Date(this.item!.visitDate).native(false),
      to: new Date(this.item!.visitDate).native(false)
    });
  }

  makePay() {
    if (this.item) {
      if (((this.item.totalAmount || 0) - (this.item.payedAmount || 0) - this.item.prepaid) <= 0) {
        this.changeState('Y');
      } else {
        this.payment.amount = Number(Number(this.debt < 0 ? Math.abs(this.debt) : 0).toFixed(2))
        this.isPrepaid = false;
        this.showPay = true
      }
    }
  }

  makePrepaid() {
    if (this.item) {
      this.payment.amount = 0;
      this.isPrepaid = true;
      this.showPay = true
    }
  }

  storePay() {
    if (!this.processing) {
      this.processing = true
      this.storePayNow()
    }
  }

  async storePayNow() {
    if (this.item) {
      try {
        await $app.post('/api/patients/' + this.item.patientId + '/visits/' + this.item.id + '/payments', this.payment);
        this.showPay = false;
        this.item.payedAmount = this.item.payedAmount + this.payment.amount;

        if (((this.item.totalAmount || 0) - (this.item.payedAmount || 0) - this.item.prepaid) <= 0) {
          this.changeState('Y');
        } else {
          this.$emit('updated', this.item);
        }
      } catch (error) {
        $app.pushError(error);
      }
      setTimeout(() => { this.processing = false }, 2000)
    }
  }

  async storePrepaid() {
    if (this.item) {
      this.processing = true;
      try {
        await $app.post('/api/patients/' + this.item.patientId + '/visits/' + this.item.id + '/prepaids', this.payment);
        this.showPay = false;
        this.item.prepaid = this.item.prepaid + this.payment.amount;

        if (this.payment.amount === 0) {
          this.changeState('Y');
        } else {
          this.$emit('updated', this.item);
        }
      } catch (error) {
        $app.pushError(error);
      }
      this.processing = false;
    }
  }

  async store() {
    if (this.item) {
      this.processing = true;
      try {
        this.item.visitDate = this.item.timeFrom || new Date().native(false);
        const updated = await $app.post('/api/patients/' + this.item.patientId + '/visits', this.item);
        this.item.id = updated.id;
        this.$emit('updated', this.item);
        this.visible = false;
      } catch (error) {
        $app.pushError(error);
      }
      this.processing = false;
    }
  }

  async changeState(state: string, cancelCode: any = null) {
    if (this.item) {
      this.showCancel = false;
      this.processing = true;
      const oldState = this.item.state;
      try {
        this.item.cancelCode = cancelCode;
        this.item.state = state;
        await $app.patch('/api/patients/' + this.item.patientId + '/visits/' + this.item.id, this.item);
        this.$emit('updated', this.item);
      } catch (error) {
        this.item.state = oldState;
        $app.pushError(error);
      }
      this.processing = false;
    }
  }

  async makeReceipt() {
    if (this.item) {
      window.open(window.location.origin + '/reports?report=VisitReceipt&patient=' + this.patientId + '&id=' + this.item.id, '_blank');
    }
  }
}
